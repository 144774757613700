import React, { createContext, useContext, useState } from 'react';

export function useLoginState(initialValue) {
    const [loginState, setLoginState] = useState(initialValue);

    const updateLoginState = (newState) => {
        setLoginState(newState);
    };

    return { loginState, updateLoginState };
}